<template>
  <div>
    <div class="header-text pb-3" data-cy="header-admin-home">
      Administration
    </div>
    <div>
      <div class="secondhead-text">Submitted Fieldwork</div>
      <div v-if="isLoadingFieldwork">
        <LoadingSpinner />
      </div>
      <div v-else>
        <dynamic-table
          :records="this.fieldworks"
          :fields="fieldworkTableConfig"
          :pagination-page="fieldworkPage"
          :pagination-total-items="fieldworkTotal"
          :id-name="'fieldwork_id'"
          :from-route="'AdminFieldworks'"
          :row-path="'FieldworksView'"
          @pageChanged="nextFieldworkPage($event)"
          no-data-msg=""
          hover
          striped
          pagination
        >
          <template #cell(student-name)="{ item }">
            <div
              @click="$router.push(`/admin/fieldworks/${item.fieldwork_id}`)"
            >
              {{ item.student.firstname }}
              {{ item.student.lastname }}
            </div>
          </template>
          <template #cell(description)="{ item }">
            <div
              @click="$router.push(`/admin/fieldworks/${item.fieldwork_id}`)"
            >
              <div v-if="item.fieldwork_type_name === 'Experience'">
                {{ experience_lookup(item) }}
              </div>
            </div>
          </template>
          <template #cell(fieldwork-academic-level)="{ item }">
            <div
              @click="$router.push(`/admin/fieldworks/${item.fieldwork_id}`)"
            >
              {{ item.acad_level.level }}
            </div>
          </template>
          <template #cell(fieldwork-last-updated)="{ item }">
            <div
              @click="$router.push(`/admin/fieldworks/${item.fieldwork_id}`)"
            >
              {{ TableDateFormat(item.added) }}
            </div>
          </template>
          <template #cell(fieldwork-comments)="{ item }">
            <div
              @click="$router.push(`/admin/fieldworks/${item.fieldwork_id}`)"
            >
              Foo
              <!--                  <entry-messages latest in-table :_id="item.fieldwork_id" />-->
            </div>
          </template>
          <template #cell(fieldwork-status)="{ item }">
            <div
              @click="$router.push(`/admin/fieldworks/${item.fieldwork_id}`)"
            >
              <div class="my-1 w-1/2 px-0.5 mx-2">
                <div
                  class="rounded-full flex items-center justify-center px-1 py-0.5"
                  :class="{
                    'bg-purple': item.event_status === 'TODO',
                    'bg-alert-warn': item.event_status === 'RETURNED',
                    'bg-alert-success': item.event_status === 'APPROVED',
                    'bg-orange': item.event_status === 'REJECTED',
                    'bg-alert-danger': item.event_status === 'DELETED',
                  }"
                >
                  <font-awesome-icon
                    :class="
                      item.event_status === 'RETURNED'
                        ? 'text-returned-text'
                        : 'text-white'
                    "
                    :icon="
                      item.event_status === 'APPROVED'
                        ? 'check'
                        : item.event_status === 'RETURNED'
                        ? 'reply'
                        : item.event_status === 'TODO'
                        ? 'hourglass'
                        : 'ban'
                    "
                  />
                </div>
              </div>
            </div>
          </template>
          <template #cell(fieldwork-date)="{ item }">
            <div
              @click="$router.push(`/admin/fieldworks/${item.fieldwork_id}`)"
            >
              {{ TableDateFormat(item.fieldwork_date) }}
            </div>
          </template>
          <template #cell(student-details)="{ item }">
            <button
              aria-label="view-fieldwork-details-button"
              :id="item.student.person_id"
              class="w-6 h-6 text-dark-purple bg-white hover:bg-dark-purple hover:text-white border rounded"
              @click="$router.push(`/admin/fieldworks/${item.fieldwork_id}`)"
            >
              <font-awesome-icon icon="user-circle" />
            </button>
          </template>
        </dynamic-table>
      </div>
      <div class="secondhead-text pt-8">Student Status At-A-Glance</div>
      <div v-if="isLoadingStudent">
        <LoadingSpinner />
      </div>
      <div v-else>
        <dynamic-table
          :records="student_status"
          :fields="studentTableConfig"
          :pagination-page="studentPage"
          :pagination-total-items="studentTotal"
          :id-name="'student_id'"
          @pageChanged="nextStudentPage($event)"
          no-data-msg=""
          striped
          pagination
        >
          <template #cell(name)="{ item }">
            <a
              class="text-purple hover:cursor-pointer"
              aria-label="see student detail page for this student"
              @click="loadStudent(item.id)"
            >
              {{ item.firstname }} {{ item.lastname }}
            </a>
          </template>
          <template #cell(poc)="{ item }">
            <div class="my-1 w-1/2 px-0.5 mx-2">
              <div
                class="rounded-full flex items-center justify-center px-1 py-0.5"
                :class="item.poc ? 'bg-alert-success' : 'bg-orange'"
              >
                <font-awesome-icon
                  :class="'text-white'"
                  :icon="item.poc ? 'check' : 'ban'"
                />
              </div>
            </div>
          </template>
          <template #cell(plan)="{ item }">
            <div class="my-1 w-1/2 px-0.5 mx-2">
              <div
                class="rounded-full flex items-center justify-center px-1 py-0.5"
                :class="item.plan ? 'bg-alert-success' : 'bg-orange'"
              >
                <font-awesome-icon
                  :class="'text-white'"
                  :icon="item.plan ? 'check' : 'ban'"
                />
              </div>
            </div>
          </template>
          <template #cell(ppdp)="{ item }">
            <div class="my-1 w-1/2 px-0.5 mx-2">
              <div
                class="rounded-full flex items-center justify-center px-1 py-0.5"
                :class="item.ppdp ? 'bg-alert-success' : 'bg-orange'"
              >
                <font-awesome-icon
                  :class="'text-white'"
                  :icon="item.ppdp ? 'check' : 'ban'"
                />
              </div>
            </div>
          </template>
          <template #cell(oct)="{ item }">
            <div class="my-1 w-1/2 px-0.5 mx-2">
              <div
                class="rounded-full flex items-center justify-center px-1 py-0.5"
                :class="item.oct ? 'bg-alert-success' : 'bg-orange'"
              >
                <font-awesome-icon
                  :class="'text-white'"
                  :icon="item.oct ? 'check' : 'ban'"
                />
              </div>
            </div>
          </template>
          <template #cell(mid)="{ item }">
            <div class="my-1 w-1/2 px-0.5 mx-2">
              <div
                class="rounded-full flex items-center justify-center px-1 py-0.5"
                :class="item.mid ? 'bg-alert-success' : 'bg-orange'"
              >
                <font-awesome-icon
                  :class="'text-white'"
                  :icon="item.mid ? 'check' : 'ban'"
                />
              </div>
            </div>
          </template>
          <template #cell(jan)="{ item }">
            <div class="my-1 w-1/2 px-0.5 mx-2">
              <div
                class="rounded-full flex items-center justify-center px-1 py-0.5"
                :class="item.jan ? 'bg-alert-success' : 'bg-orange'"
              >
                <font-awesome-icon
                  :class="'text-white'"
                  :icon="item.jan ? 'check' : 'ban'"
                />
              </div>
            </div>
          </template>
          <template #cell(feb)="{ item }">
            <div class="my-1 w-1/2 px-0.5 mx-2">
              <div
                class="rounded-full flex items-center justify-center px-1 py-0.5"
                :class="item.feb ? 'bg-alert-success' : 'bg-orange'"
              >
                <font-awesome-icon
                  :class="'text-white'"
                  :icon="item.feb ? 'check' : 'ban'"
                />
              </div>
            </div>
          </template>
          <template #cell(eoy)="{ item }">
            <div class="my-1 w-1/2 px-0.5 mx-2">
              <div
                class="rounded-full flex items-center justify-center px-1 py-0.5"
                :class="item.eoy ? 'bg-alert-success' : 'bg-orange'"
              >
                <font-awesome-icon
                  :class="'text-white'"
                  :icon="item.eoy ? 'check' : 'ban'"
                />
              </div>
            </div>
          </template>
          <template #cell(yearend)="{ item }">
            <div class="my-1 w-1/2 px-0.5 mx-2">
              <div
                class="rounded-full flex items-center justify-center px-1 py-0.5"
                :class="item.yearend ? 'bg-alert-success' : 'bg-orange'"
              >
                <font-awesome-icon
                  :class="'text-white'"
                  :icon="item.yearend ? 'check' : 'ban'"
                />
              </div>
            </div>
          </template>
        </dynamic-table>
      </div>
    </div>
  </div>
</template>

<script>
import { isAdm, isSra, isFac, isSaj } from "@/composables/tokenUtils";
import { get } from "@/composables/httpUtil";
import DynamicTable from "@/components/displays/admin-displays/DynamicTable";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import { mapGetters } from "vuex";
import { TableDateFormat } from "@/composables/date_utils";

export default {
  name: "AdminHome",
  data() {
    return {
      itemsPerPage: 15,

      isLoadingFieldwork: false,
      fieldworks: [],
      fieldworkTotal: 0,
      fieldworkPages: 0,
      fieldworkPage: 1,
      fieldworkTableConfig: [
        {
          display: "Student Name",
          name: "student-name",
          sortable: false,
          class: "hover:cursor-pointer text-left",
        },
        {
          display: "Type",
          name: "fieldwork_type_name",
          sortable: false,
          class: "hover:cursor-pointer text-left",
        },
        {
          display: "Description",
          name: "description",
          sortable: false,
          class: "hover:cursor-pointer text-left",
        },
        {
          display: "Academic Level",
          name: "fieldwork-academic-level",
          sortable: false,
          class: "hover:cursor-pointer text-center",
        },
        {
          display: "Date of Fieldwork",
          name: "fieldwork-date",
          sortable: false,
          class: "hover:cursor-pointer text-left",
        },
        {
          display: "Date Submitted",
          name: "fieldwork-last-updated",
          sortable: false,
          class: "hover:cursor-pointer text-left",
        },
        {
          display: "Status",
          name: "fieldwork-status",
          sortable: false,
          class: "hover:cursor-pointer text-left",
        },
        {
          display: "Student Details",
          name: "student-details",
          sortable: false,
          class: "text-center",
        },
      ],

      isLoadingStudent: false,
      student_status: [],
      studentTotal: 0,
      studentPages: 0,
      studentPage: 1,
      studentTableConfig: [
        {
          display: "Student Name",
          name: "name",
          sortable: false,
          class: "",
        },
        {
          display: "Academic Level",
          name: "level",
          sortable: false,
          class: "",
        },
        {
          display: "Approved Hours",
          name: "hours",
          sortable: false,
          class: "",
        },
        {
          display: "POC",
          name: "poc",
          sortable: false,
          class: "",
        },
        {
          display: "Plan",
          name: "plan",
          sortable: false,
          class: "",
        },
        {
          display: "Meet",
          name: "ppdp",
          sortable: false,
          class: "",
        },
        {
          display: "Oct",
          name: "oct",
          sortable: false,
          class: "",
        },
        {
          display: "Mid",
          name: "mid",
          sortable: false,
          class: "",
        },
        {
          display: "Jan",
          name: "jan",
          sortable: false,
          class: "",
        },
        {
          display: "Feb",
          name: "feb",
          sortable: false,
          class: "",
        },
        {
          display: "EOY",
          name: "eoy",
          sortable: false,
          class: "",
        },
        {
          display: "Meet",
          name: "yearend",
          sortable: false,
          class: "",
        },
      ],
    };
  },
  components: {
    DynamicTable,
    LoadingSpinner,
  },
  mounted() {
    if (isSaj()) {
      this.$router.push("/admin/fieldworks");
    } else if (isFac()) {
      this.$router.push("/admin/students");
    } else if (isSra()) {
      this.$router.push("/admin/mentors");
    } else {
      this.getStudentStatus();
      this.getFieldwork();
    }
  },
  methods: {
    isAdm,
    isSra,
    isFac,
    isSaj,
    get,
    TableDateFormat,
    loadStudent(id) {
      this.$router.push(`/admin/students/detail/${id}`)
    },
    getFieldwork() {
      this.isLoadingFieldwork = true;
      let url = `admin/fieldworks?page=${this.fieldworkPage}&limit=${this.itemsPerPage}&term=${this.lookup_current_term.term_id}&status=TODO`;
      get(url)
        .then((response) => {
          this.fieldworks = response.data.map((fw) => {
            const entry_type = this.lookup_fieldwork_types.find(
              (type) => type.fieldwork_log_type_id === fw.fieldwork_type_id
            );
            fw.entry_type_name = entry_type?.fieldwork_type_name;
            return fw;
          });
          this.fieldworkTotal = response.count;
          this.studentPages = response.total_pages;
          this.isLoadingFieldwork = false;
        })
        .catch((error) => {
          console.log("error (getFieldwork):", error);
          this.isLoadingFieldwork = false;
        });
    },
    getStudentStatus() {
      this.isLoadingStudent = true;
      return this.get(
        `admin/reports/student_status?page=${this.studentPage}&limit=5`
      ).then((data) => {
        this.student_status = data.data;
        this.studentTotal = data.count;
        this.studentPages = data.total_pages;
        this.isLoadingStudent = false;
      });
    },
    nextStudentPage: function (info) {
      this.studentPage = info.newPage;
      this.getStudentStatus();
    },
    nextFieldworkPage: function (info) {
      this.fieldworkPage = info.newPage;
      this.getFieldwork();
    },
    experience_lookup: function (item) {
      let exp_desc = "";
      const lookup = this.lookup_fieldwork_types.find(
        (l) => l.fieldwork_log_type_id === item.fieldwork_type_id
      );
      item.fields.forEach((field) => {
        let value = lookup.fields.find(
          (l) => field.fieldwork_log_field_id === l.field_id
        );
        if (value.name === "Experience Type") {
          exp_desc = field.value;
        }
      });
      return exp_desc;
    },
  },
  computed: {
    ...mapGetters(["lookup_fieldwork_types", "lookup_current_term"]),
  },
};
</script>

<style scoped></style>
